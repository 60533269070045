<template>
    <ul class="state-list">
        <li v-for="(item,index) in stateList"
            class="state-item"
            :class="currentState ==  item.id ? 'current' : ''"
            :key="index"
            @click="changeState(item)">
            <i class="circle" :class="circleClass(item.id)"></i>
            <span class="sum-num">{{item.label+item.sumNum}}</span>
        </li>
    </ul>

</template>

<script>
	export default {
		name:"technician-state-list",
		props:{
			stateList:{
				type:Array,
				default:() => []
			},
			currentState:{
				type:String,
				default:'-1'
			}
		},
		computed:{
			circleClass(){
				return function(state){
					state = parseInt(state,10)
					switch(state){
						case -1:
							return ''
							break
						case 1:
							return 'idle'
							break
						case 2:
							return 'upper-clock'
							break
						case 3:
							return 'lock-card'
							break
						case 4:
							return 'off-work'
							break
						case 5:
							return 'vacation'
						case 6:
							return 'beautiful'
							break
						case 7:
							return 'order'
							break
					}
				}
			},
		},
		methods:{
			changeState(item){
				this.$emit('changeState',item.id)
			}
		}
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .state-list{
        width:100vw;
        overflow-x: scroll;
        overflow-y: hidden;
        background:#fff;
        text-align:center;
        color:#4D555E;
        display:flex;
        z-index:2;
        padding:30px 10px;
        white-space:nowrap;

    }
    .state-list::-webkit-scrollbar-track-piece {
        background-color: rgba(0, 0, 0, 0);
        border-left: 1px solid rgba(0, 0, 0, 0);
    }
    .state-list::-webkit-scrollbar {
        width: 5px;
        height: 6px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .state-list::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        background-clip: padding-box;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .state-listlist::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .state-item{
        display:block;
        display:flex;
        border: #d2d2d2 1px solid;
        border-radius:5px;
        margin-right:10px;
        padding:15px 10px;
        &.current{
            background:#f3f3f3;
            border-color:#535353;

        }
    }

    .circle{
        width:20px;
        height:20px;
        border-radius:5px;
        color:#FFFFFF;
        font-size:24px;
        background:$high-light-color;
    }

    .upper-clock{
        background:#15BB44;
    }

    .idle {
        background:#93A3B5;
    }

    .lock-card {
        background:#5B3CFC;
    }

    .off-work {
        background:#98c9fd;
    }

    .vacation {
        background:#F77E4E;
    }
    .beautiful{
        background:#e13744;
    }
    .order{
        background:#fff;
    }
    .sum-num{
        font-size:22px;
        line-height:1;
        padding-left:10px;
    }

</style>
